// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-acceptable-use-policy-tsx": () => import("./../../../src/pages/acceptable_use_policy.tsx" /* webpackChunkName: "component---src-pages-acceptable-use-policy-tsx" */),
  "component---src-pages-demo-video-tsx": () => import("./../../../src/pages/demo_video.tsx" /* webpackChunkName: "component---src-pages-demo-video-tsx" */),
  "component---src-pages-events-nila-tsx": () => import("./../../../src/pages/events/nila.tsx" /* webpackChunkName: "component---src-pages-events-nila-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investors-tsx": () => import("./../../../src/pages/investors.tsx" /* webpackChunkName: "component---src-pages-investors-tsx" */),
  "component---src-pages-platform-course-management-tsx": () => import("./../../../src/pages/platform/course-management.tsx" /* webpackChunkName: "component---src-pages-platform-course-management-tsx" */),
  "component---src-pages-platform-engaging-content-tsx": () => import("./../../../src/pages/platform/engaging-content.tsx" /* webpackChunkName: "component---src-pages-platform-engaging-content-tsx" */),
  "component---src-pages-platform-insightful-analytics-tsx": () => import("./../../../src/pages/platform/insightful-analytics.tsx" /* webpackChunkName: "component---src-pages-platform-insightful-analytics-tsx" */),
  "component---src-pages-platform-personalized-learning-tsx": () => import("./../../../src/pages/platform/personalized-learning.tsx" /* webpackChunkName: "component---src-pages-platform-personalized-learning-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy_policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-stay-safe-tsx": () => import("./../../../src/pages/stay_safe.tsx" /* webpackChunkName: "component---src-pages-stay-safe-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms_of_service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */)
}

